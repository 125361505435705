import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import Table from "../../common/table/Table.component";
import DialogModal from "../../common/modal/Modal.component";
import CreateInputEquipment from "../create-input-equipment/createInputEquipment.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { InputEquipmentModel } from "../../models/InputModels";
import Joi from "joi-browser";
import {
  handleSave,
  validateForm,
  serverValidation,
} from "../../common/validation/validation";

const InputEquipments = ({ handleAddEquipment, equipments, disabled }) => {
  const { t } = useTranslation();
  const tableName = "Input_Equipments";
  const [edit, setEdit] = useState(false);
  const [equipmentIndex, setEquipmentIndex] = useState(-1);
  const [equipmentData, setEquipmentData] = useState(new InputEquipmentModel());
  const [errors, setErrors] = useState({});

  const columns = [
    {
      Header: t("EquipmentName"),
      accessor: "name",
    },
    {
      Header: t("Model"),
      accessor: "model",
    },
    {
      Header: t("Type"),
      accessor: "type",
    },
    {
      Header: t("IdentificationNumber"),
      accessor: "identificationNumber",
    },
    {
      accessor: "edit",
      className: "two-buttons-column",
      Cell: ({ row }) => {
        return (
          <>
            <button
              type="button"
              className="btn btn-incomplete table-btn"
              title="Izmena opreme"
              onClick={() => {
                setEdit(true);
                setEquipmentIndex(row.index);
                setEquipmentData(row.original);
                setErrors({});
                ShowDialog();
              }}
            >
              <FontAwesomeIcon icon={solid("pencil")} />
            </button>
            <button
              type="button"
              className="btn btn-danger table-btn"
              title="Brisanje opreme"
              onClick={() => removeEquipment(row.index)}
            >
              <FontAwesomeIcon icon={solid("trash")} />
            </button>
          </>
        );
      },
    },
  ];

  const [data, setData] = useState([]);

  const [show, setShow] = useState(false);

  const schema = {
    name: Joi.string().required(),
    model: Joi.string().required(),
    type: Joi.string().required(),
    identificationNumber: Joi.string().required(),
  };

  useEffect(() => {
    if (equipments) {
      setData(equipments);
    }
  }, [equipments]);

  const ShowDialog = () => setShow(true);
  const HideDialog = () => setShow(false);

  const handleChange = (prop, value, equipment) => {
    const obj = { name: prop, value: value };
    const result = handleSave(obj, equipment, errors, schema, t);
    setErrors(result.errors);
  };

  const addEquipment = (equipment) => {
    const errorsResult = validateForm(equipment, schema, t);
    setErrors(errorsResult);

    if (!errorsResult) {
      if (edit) {
        let dataCopy = data;
        dataCopy[equipmentIndex] = equipment;
        setData(dataCopy);
        handleAddEquipment(dataCopy);
      } else {
        setData([...data, equipment]);
        handleAddEquipment([...data, equipment]);
      }
      HideDialog();
    }
  };

  const removeEquipment = (index) => {
    let newData = data.filter((v, i) => i !== index);
    setData(newData);
    handleAddEquipment(newData);
  };

  return (
    <>
      {data.length > 0 &&
        <>
          <h5>{t("InputEquipments")}</h5>
          <Table
            columns={columns}
            data={data}
            tableName={tableName}
            rememberFilter={false}
            hiddenColumn={disabled && "edit"}
          />
        </>
      }
      {!disabled &&
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            setEdit(false);
            setEquipmentIndex(-1);
            setEquipmentData(new InputEquipmentModel());
            setErrors({});
            ShowDialog();
          }}
        >
          {t("AddInputEquipment")}
        </button>
      }
      <DialogModal
        show={show}
        onHide={HideDialog}
        title={
          edit ? "Izmena mehanizacije i opreme za rad" : t("InputEquipments")
        }
      >
        <CreateInputEquipment
          propertyChange={handleChange}
          addEquipment={addEquipment}
          equipmentData={equipmentData}
          edit={edit}
          errors={errors}
        ></CreateInputEquipment>
      </DialogModal>
    </>
  );
};

export default InputEquipments;
