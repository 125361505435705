import UserService from "../../../utils/authentification/keycloak";
import { useEffect } from "react";

const Login = () => {
  useEffect(() => {
    UserService.doLogin();
  }, []);

  return (
    <>
    </>
  );
};

export default Login;
