import { Route, Routes } from "react-router-dom";
import "./App.css";
import Layout from "./common/layout/Layout.component";
import Login from "./common/pages/login/Login";
import PublicRoute from "./common/route-and-role/publicRoute";
import ProtectedRoute from "./common/route-and-role/protectedRoute";
import NotAllowed from "./common/pages/not-allowed/NotAllowed";

import Index from "./pages/index";
import Profile from "./pages/profile/profile";
import CreateInputWithBZR from "./pages/create-input-bzr/createInputWithBZR";
import CreateInputWithoutBZR from "./pages/create-input/createInputWithoutBZR";

function App() {
  return (
    <Routes>
      <Route
        path="/login"
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      ></Route>
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <Layout />
          </ProtectedRoute>
        }
      >
        <Route path="/" element={<Index />} />
        <Route path="/CreateInputWithBZR/:id?" element={<CreateInputWithBZR />} />
        <Route
          path="/CreateInputWithoutBZR/:id?"
          element={<CreateInputWithoutBZR />}
        />
        <Route path="/MyProfile" element={<Profile />} />
      </Route>
      <Route path="/" element={<Layout />}>
        <Route path="/notAllowed" element={<NotAllowed />} />
      </Route>
    </Routes>
  );
}

export default App;
