import api from "../api";
import HandlingResponse from "../common/handling-response/handlingResponse";
import { Success } from "../common/toastify/toastify";
import { env } from "../env";

var baseURL = env.REACT_APP_API_URL_EEP;
var successCode = "200";

class Client {
  async GetAllInputs() {
    try {
      var response = await api.get(baseURL + "/InputWorkflow/GetAll");
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async GetInput(id) {
    try {
      var response = await api.get(baseURL + "/InputWorkflow/Get?id=" + id);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async CreateInput(data) {
    try {
      var response = await api.post(
        baseURL + "/InputWorkflow/CreateFirstStep",
        data
      );
      Success(successCode);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async UpdateInput(data) {
    try {
      var response = await api.post(
        baseURL + "/InputWorkflow/UpdateFirstStep",
        data
      );
      Success(successCode);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async SearchInputs(data) {
    try {
      var response = await api.post(baseURL + "/InputWorkflow/Search", data);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async SearchPersons(data, isBackground) {
    try {
      var response = await api.post(
        baseURL + "/BzrPersonAndEquipment/SearchPersons",
        data,
        { isBackground: isBackground }
      );
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async SearchEquipments(data, isBackground) {
    try {
      var response = await api.post(
        baseURL + "/BzrPersonAndEquipment/SearchEquipments",
        data,
        { isBackground: isBackground }
      );
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async DownloadFirstAttachment(id) {
    try {
      var response = await api
        .get(
          baseURL + "/InputWorkflow/DownloadAttachmentOneThirdStep?id=" + id,
          { responseType: "blob" }
        )
        .then(({ data }) => {
          const blob = new Blob([data]);
          const link = document.createElement("a");
          const url = URL.createObjectURL(blob);
          link.href = url;
          link.download = "Prilog 1.pdf";
          link.click();
        });
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async DownloadSecondAttachment(id) {
    try {
      var response = await api
        .get(
          baseURL + "/InputWorkflow/DownloadAttachmentTwoThirdStep?id=" + id,
          { responseType: "blob" }
        )
        .then(({ data }) => {
          const blob = new Blob([data]);
          const link = document.createElement("a");
          const url = URL.createObjectURL(blob);
          link.href = url;
          link.download = "Prilog 2.pdf";
          link.click();
        });
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async FinishThirdStep(data, isBackground) {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    try {
      var response = await api.post(
        baseURL + "/InputWorkflow/FinishThirdStep",
        data,
        {
          headers: headers,
        },
        { isBackground: isBackground }
      );
      Success(successCode);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async GetUserProfile() {
    try {
      var response = await api.get(baseURL + "/ExternalUser/GetUserProfile");
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async ResetPassword(data) {
    try {
      var response = await api.post(
        baseURL + "/ExternalUser/ResetPasswordForExternalUser",
        data
      );
      Success(successCode);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async SearchSapContracts(data, isBackground) {
    try {
      var response = await api.post(
        baseURL + "/ExternalUser/SearchSapContracts",
        data,
        { isBackground: isBackground }
      );
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }
}

export default Client;
