import React from 'react';

const StatusEnum = {
  1: "Započet proces", // StartWorkflow
  2: "Završen korak", // FinishStep
  3: "Izmena", // Update 
  4: "Dodavanje SAP ugovora", // AddSapContract 
  5: "Dodavanje dokumenta", // AddDocument
  6: "Završen proces", // FinishWorkflow
};

const HistoryStatus = ({ value }) => {
  return <span>{StatusEnum[value]}</span>;
};

export default HistoryStatus;