if (process.env.NODE_ENV === "development") {
  window.env = {
    REACT_APP_API_URL_EEP: "https://aams-api.dev.apps.coming.rs/api",
    REACT_APP_URL: "https://keycloak.dev.apps.coming.rs",
    REACT_APP_REALM: "AAMS_ExternalUsers-DEV", 
    REACT_APP_CLIENT_ID: "AAMS.Api"
  };
}

export const env = { ...process.env, ...window["env"] };
