import { useTranslation } from "react-i18next";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import {
  DeleteButton,
  NewFileBtn,
} from "../../common/multiple-files/multipleFiles.style";
import DialogModal from "../../common/modal/Modal.component";
import api from "../../api";
import { env } from "../../env";

var baseURL = env.REACT_APP_API_URL_EEP;

const InputDocuments = ({
  firstAttachment,
  setFirstAttachment,
  firstAttachmentRef,
  secondAttachment,
  setSecondAttachment,
  secondAttachmentRef,
  id,
  withBzr,
}) => {
  const { t } = useTranslation();

  const [show, setShow] = useState(false);
  const [doc, setDoc] = useState(null);
  const [docName, setDocName] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);

  const ShowDialog = () => setShow(true);
  const HideDialog = () => setShow(false);

  const downloadFirstAttachment = async () => {
    let url =
      baseURL + "/InputWorkflow/DownloadAttachmentOneThirdStep?id=" + id;
    let urlName = "Prilog 1";
    getDocument(url, urlName);
    //await client.DownloadFirstAttachment(id);
  };

  const downloadSecondAttachment = async () => {
    let url =
      baseURL + "/InputWorkflow/DownloadAttachmentTwoThirdStep?id=" + id;
    let urlName = "Prilog 2";
    getDocument(url, urlName);
    //await client.DownloadSecondAttachment(id);
  };

  const handleUploadFirstAttachmentClick = () => {
    firstAttachmentRef.current.value = null;
    firstAttachmentRef.current?.click();
  };

  const removeFirstAttachment = () => {
    setFirstAttachment(null);
    firstAttachmentRef.current.value = null;
  };

  const handleUploadSecondAttachmentClick = () => {
    secondAttachmentRef.current.value = null;
    secondAttachmentRef.current?.click();
  };

  const removeSecondAttachment = () => {
    setSecondAttachment(null);
    secondAttachmentRef.current.value = null;
  };

  const downloadStaticFile = async () => {
    let url = baseURL + "/InputWorkflow/DownloadStaticFile";
    let urlName = t("StaticFileName");
    getDocument(url, urlName);
    setIsDisabled(false);
  };

  const getDocument = async (urlName, fileName) => {
    let response = await api.get(urlName, {
      responseType: "blob",
    });
    var blob = new Blob([response.data], { type: response.data.type });
    var url = window.URL.createObjectURL(blob);
    setDoc(url);
    setDocName(fileName);
    ShowDialog();
  };

  const downloadDocument = () => {
    const link = document.createElement("a");
    link.href = doc;
    link.download = docName;
    link.click();
  };

  return (
    <>
      <div className="form-group col-6">
        <label>{t("StaticFileName")}</label>
        <br />
        <button
          type="button"
          className="btn btn-primary m-2"
          onClick={downloadStaticFile}
        >
          {t("DownloadFile")}
        </button>
      </div>
      <label className="red-text">{t("DownloadNote")}</label>
      <div className="form-group col-6">
        <label>{t("FirstAttachment")}</label>
        <br />
        <button
          type="button"
          className="btn btn-primary m-2"
          onClick={downloadFirstAttachment}
          disabled={isDisabled}
        >
          {t("DownloadFirstAttachment")}
        </button>
        {firstAttachment ? (
          <>
            {firstAttachment?.name}
            <DeleteButton
              title={t("DeleteFile")}
              type="button"
              onClick={() => removeFirstAttachment()}
            >
              <FontAwesomeIcon icon={solid("times")} />
            </DeleteButton>
          </>
        ) : (
          <NewFileBtn onClick={handleUploadFirstAttachmentClick} type="button" disabled={isDisabled}>
            <FontAwesomeIcon icon={solid("plus")} />
            {t("UploadFirstAttachment")}
          </NewFileBtn>
        )}
        <input
          type="file"
          ref={firstAttachmentRef}
          onChange={(event) => setFirstAttachment(event.target.files[0])}
          hidden
          accept=".pdf"
          name="firstAttachment"
        />
      </div>
      {withBzr && (
        <div className="form-group col-6">
          <label>{t("SecondAttachment")}</label>
          <br />
          <button
            type="button"
            className="btn btn-primary m-2"
            onClick={downloadSecondAttachment}
            disabled={isDisabled}
          >
            {t("DownloadSecondAttachment")}
          </button>
          {secondAttachment ? (
            <>
              {secondAttachment?.name}
              <DeleteButton
                title={t("DeleteFile")}
                type="button"
                onClick={() => removeSecondAttachment()}
              >
                <FontAwesomeIcon icon={solid("times")} />
              </DeleteButton>
            </>
          ) : (
            <NewFileBtn
              onClick={handleUploadSecondAttachmentClick}
              type="button"
              disabled={isDisabled}
            >
              <FontAwesomeIcon icon={solid("plus")} />
              {t("UploadSecondAttachment")}
            </NewFileBtn>
          )}
          <input
            type="file"
            ref={secondAttachmentRef}
            onChange={(event) => setSecondAttachment(event.target.files[0])}
            hidden
            accept=".pdf"
            name="secondAttachment"
          />
        </div>
      )}
      <DialogModal
        show={show}
        onHide={HideDialog}
        title={docName}
        download={true}
        downloadDocument={downloadDocument}
      >
        <div>
          <iframe src={doc} style={{ width: "100%", height: "800px" }} />
        </div>
      </DialogModal>
    </>
  );
};

export default InputDocuments;
