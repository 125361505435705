import { Navigate } from "react-router-dom";
import Login from "../pages/login/Login";
import Loader from "../loader/Loader.component";
import UserService from "../../utils/authentification/keycloak";

const PublicRoute = ({ url }) => {
  if (UserService.isLoggedIn()) return <Navigate replace to={url} />;
  else if (!UserService.isLoggedIn()) return <Login />;
  else return <Loader />;
};

export default PublicRoute;
