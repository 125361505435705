import Keycloak from "keycloak-js";
import { env } from '../../env';

const keycloak = new Keycloak({
    url: env.REACT_APP_URL,
    realm: env.REACT_APP_REALM,
    clientId: env.REACT_APP_CLIENT_ID
});

const initKeycloak = (onAuthenticatedCallback) => {
    keycloak.init({
        onLoad: 'check-sso',
        promiseType: 'native',
        silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
    }).then((authenticated) => {
        if (!authenticated) {
            console.log("user is not authenticated..!");
        }
        onAuthenticatedCallback();
    }).catch(doLogout);
};

const doLogin = keycloak.login;

const doLogout = keycloak.logout;

const getToken = () => keycloak.token;

const isLoggedIn = () => !!keycloak.token;

const updateToken = (successCallback) =>
  keycloak.updateToken(54000)
    .then(successCallback)
    .catch(doLogin);

const getUsername = () => keycloak.tokenParsed?.preferred_username;

const getFullName = () => keycloak.tokenParsed?.name;

const getUserGuid = () => keycloak.subject;

const hasRole = (roles) => roles.some((role) => keycloak.hasRealmRole(role));

const getRoles = () => keycloak.realmAccess.roles;

const UserService = {
    initKeycloak,
    updateToken,
    doLogin,
    doLogout,
    getToken,
    isLoggedIn,
    getUsername,
    getFullName,
    getUserGuid,
    hasRole,
    getRoles
}

export default UserService;