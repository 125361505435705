import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DialogModal from "../../common/modal/Modal.component";
import Client from "../../api-services/client";
import { MainContainer } from "../../common/layout/Layout.style";
import AlertDialog from "../../common/alert-dialog/AlertDialog.component";
import Joi from "joi-browser";
import {
  handleSave,
  validateForm,
} from "../../common/validation/validation";

const Profile = () => {
  const { t } = useTranslation();
  const [user, setUser] = useState({
    userName: "",
    firstName: "",
    lastName: "",
    email: "",
  });
  const [password, setPassword] = useState("");
  const [show, setShow] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [errors, setErrors] = useState({});

  const schema = {
    password: Joi.string()
      .regex(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}$/)
      .optional(),
  };

  var client = new Client();

  const ShowDialog = () => setShow(true);
  const HideDialog = () => setShow(false);
  const ShowAlertDialog = () => setShowAlert(true);
  const HideAlertDialog = () => setShowAlert(false);

  useEffect(() => {
    getUserProfile();
  }, []);

  const getUserProfile = async () => {
    let response = await client.GetUserProfile();
    setUser(response);
  };

  const handlePasswordChange = (value) => {
    const obj = { name: "password", value: value };
    const result = handleSave(obj, null, errors, schema, t);
    setPassword(value);
    setErrors(result.errors);
  };

  const checkValidation = () => {
    const errorsResult = validateForm({ password }, schema, t);
    setErrors(errorsResult);

    if (!errorsResult) {
      ShowAlertDialog();
    }
  }

  const resetPassword = async () => {
    let data = {
      id: user.id,
      password: password,
    };
    let response = await client.ResetPassword(data);
    if (response != "Error") {
      setPassword("");
      HideDialog();
    }
  };

  return (
    <MainContainer>
      <div className="form-group col-md-3">
        <label>{t("UserName")}</label>
        <input
          type="text"
          className="form-control"
          value={user.userName}
          onChange={(e) => setUser({ ...user, userName: e.target.value })}
          readOnly
        />
      </div>
      <div className="form-group col-md-3">
        <label>{t("Name")}</label>
        <input
          type="text"
          className="form-control"
          value={user.firstName}
          onChange={(e) => setUser({ ...user, firstName: e.target.value })}
          readOnly
        />
      </div>
      <div className="form-group col-md-3">
        <label>{t("LastName")}</label>
        <input
          type="text"
          className="form-control"
          value={user.lastName}
          onChange={(e) => setUser({ ...user, lastName: e.target.value })}
          readOnly
        />
      </div>
      <div className="form-group col-md-3">
        <label>{t("Email")}</label>
        <input
          type="text"
          className="form-control"
          value={user.email}
          onChange={(e) => setUser({ ...user, email: e.target.value })}
          readOnly
        />
      </div>
      <div>
        <button type="button" className="btn btn-primary" onClick={ShowDialog}>
          {t("ChangePassword")}
        </button>
      </div>
      <DialogModal show={show} onHide={HideDialog} title={t("PasswordChange")}>
        <div className="form-group">
          <label>{t("NewPassword")}</label>
          <input
            type="password"
            className="form-control"
            value={password}
            onChange={(e) => handlePasswordChange(e.target.value)}
          />
          {errors?.password && (
            <div className="text-danger">{errors.password}</div>
          )}
        </div>
        <button
          type="button"
          className="btn btn-accept pull-right"
          onClick={checkValidation}
        >
          {t("Change")}
        </button>
        {showAlert && (
          <AlertDialog
            handleYes={() => {
              HideAlertDialog();
              resetPassword();
            }}
            handleNo={() => {
              HideAlertDialog();
            }}
            show={showAlert}
            contentText={t("PasswordWillBeChanged")}
            handleYesText={t("Accept")}
            className={"btn btn-accept"}
          ></AlertDialog>
        )}
      </DialogModal>
    </MainContainer>
  );
};

export default Profile;
