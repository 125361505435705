import React from "react";
import { NavBarContainer, Logo, LogOutUser } from "./Navigation.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useTranslation } from "react-i18next";
import { useNavigate, Link } from "react-router-dom";
import "./Navigation.style.css";
import UserService from "../../utils/authentification/keycloak";

const Navbar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <NavBarContainer>
        <Logo>
          <img className="ems-logo" src="/logoems.png" />
          <span className="logo-span" onClick={() => navigate("/")}>
            {t("ExternalExecutorsPortal")}
          </span>
        </Logo>
        {UserService.isLoggedIn() && (
          <LogOutUser>
            <div className="dropdown">
              <button className="dropbtn">
                {UserService.getFullName()}
                <FontAwesomeIcon icon={solid("user")} />
              </button>
              <div className="dropdown-content">
                <Link to="/MyProfile">Moj profil</Link>
                <button
                  className="logout-btn"
                  onClick={() => {
                    sessionStorage.clear();
                    localStorage.clear();
                    UserService.doLogout({
                      redirectUri: window.location.origin + "/",
                    });
                  }}
                >
                  {t("LogOut")}
                  <FontAwesomeIcon icon={solid("sign-out")} />
                </button>
              </div>
            </div>
          </LogOutUser>
        )}
      </NavBarContainer>
    </>
  );
};

export default Navbar;
