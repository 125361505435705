import { useTranslation } from "react-i18next";
import Table, {
  getPageNumber,
  getPageSizeNumber,
  getSortBy,
} from "../../common/table/Table.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useState, useEffect } from "react";
import SearchInput from "../../components/search-input/searchInput.component";
import { useNavigate } from "react-router-dom";
import Client from "../../api-services/client";
import { format } from "date-fns";
import { InputSearchModel } from "../../models/InputModels";
import InputStatus from "../../common/input-status/InputStatus";
import { MainContainer } from "../../common/layout/Layout.style";

const Index = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const tableName = "Input_Workflows";
  const rememberFilter = true;
  const [reset, setReset] = useState(false);

  const [filterData, setFilterData] = useState(new InputSearchModel());

  const columns = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: t("RequestNumber"),
      accessor: "ledgerNumber",
    },
    {
      Header: t("Executor"),
      accessor: "companyName",
    },
    {
      Header: t("ContractNumber"),
      accessor: "contractNumber",
    },
    {
      Header: t("CreationDate"),
      accessor: "createdDateTimeOnUtc",
      Cell: ({ row }) => {
        return (
          <div>
            {row.original.createdDateTimeOnUtc
              ? format(
                  new Date(row.original.createdDateTimeOnUtc),
                  "dd.MM.yyyy"
                )
              : ""}
          </div>
        );
      },
    },
    {
      Header: t("Status"),
      accessor: "status",
      Cell: ({ value }) => <InputStatus value={value} />,
    },
    {
      Header: t("WithBzrContract"),
      accessor: "bzrWithContract",
      Cell: ({ value }) => (value ? "Da" : "Ne"),
    },
    {
      accessor: "preview",
      className: "one-button-column",
      Cell: ({ row }) => {
        return (
          <>
            <button
              type="button"
              className="btn btn-primary table-btn"
              title="Pregled unosa"
              onClick={() => {
                updateInput(row.original.id, row.original.bzrWithContract);
              }}
            >
              <FontAwesomeIcon icon={solid("search")} />
            </button>
          </>
        );
      },
    },
  ];

  const [data, setData] = useState([]);

  var client = new Client();

  useEffect(() => {
    reset && setReset(false);
  }, [data]);

  const fetchDataForPage = async (pageNumber, pageSize, sortBy) => {
    const storedObject = sessionStorage.getItem("INPUT_FILTER");
    if (storedObject) {
      var parsedObject = JSON.parse(storedObject);
      setFilterData(parsedObject);
    }

    let filter = storedObject ? parsedObject : filterData;

    var response = await client.SearchInputs({
      filter: {
        ...filter,
        statuses:
          filter.statuses && filter.statuses.length > 0
            ? filter.statuses.map((x) => {
                return x.value;
              })
            : null,
      },
      pageSize: pageSize,
      page: pageNumber,
      fieldNamesForSort: sortBy,
    });

    return { dataList: response.results, count: response.totalCount };
  };

  const searchData = async () => {
    sessionStorage.setItem("INPUT_FILTER", JSON.stringify(filterData));
    const result = await fetchDataForPage(
      getPageNumber(tableName, rememberFilter) + 1,
      getPageSizeNumber(tableName, rememberFilter),
      getSortBy(tableName, rememberFilter, true)
    );
    setData(result);
  };

  const resetFilters = async () => {
    setReset(true);
    sessionStorage.removeItem("INPUT_FILTER");
    let data = new InputSearchModel();
    setFilterData(data);
    var response = await client.SearchInputs({
      filter: data,
    });
    setData({ dataList: response.results, count: response.totalCount });
  };

  const updateInput = async (id, bzrWithContract) => {
    if (bzrWithContract) {
      navigate("/CreateInputWithBZR/" + id);
    } else {
      navigate("/CreateInputWithoutBZR/" + id);
    }
  };

  return (
    <MainContainer>
      <SearchInput
        filterData={filterData}
        setFilterData={setFilterData}
        handleCallback={searchData}
        resetFilters={resetFilters}
      />
      <br />
      <div className="col-md-12">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            navigate("/CreateInputWithBZR");
          }}
        >
          {t("CreateInputWithBZR")}
        </button>
        <button
          type="button"
          className="btn btn-primary"
          style={{ marginLeft: "10px" }}
          onClick={() => {
            navigate("/CreateInputWithoutBZR");
          }}
        >
          {t("CreateInputWithoutBZR")}
        </button>
      </div>
      <br />
      <Table
        columns={columns}
        data={data}
        tableName={tableName}
        rememberFilter={rememberFilter}
        fetchFunction={fetchDataForPage}
        serverSide={true}
        setData={setData}
        hiddenColumn={"id"}
        resetFilters={reset}
      />
    </MainContainer>
  );
};

export default Index;
