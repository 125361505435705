import { useTranslation } from "react-i18next";
import Select from "react-select";
import { style } from "../../common/select-style/SelectStyle";

const SearchInput = ({
  filterData,
  setFilterData,
  handleCallback,
  resetFilters,
}) => {
  const { t } = useTranslation();

  const statusOptions = [
    {
      value: 1,
      label: "Novi",
    },
    {
      value: 2,
      label: "Čeka potpis",
    },
    {
      value: 3,
      label: "Čeka odobrenje",
    },
    {
      value: 4,
      label: "Odobren",
    },
    {
      value: 5,
      label: "Odbijen",
    },
    {
      value: 6,
      label: "Nepotpun",
    },
    {
      value: 7,
      label: "Novi dopunjen",
    },
    {
      value: 8,
      label: "Neispravno potpisan",
    },
    {
      value: 9,
      label: "Ponovo potpisan",
    },
  ];

  return (
    <form>
      <div className="row">
        <div className="form-group col-md-4">
          <label>{t("RequestNumber")}</label>
          <input
            type="text"
            className="form-control"
            value={filterData.ledgerNumber}
            onChange={(e) =>
              setFilterData({ ...filterData, ledgerNumber: e.target.value })
            }
          />
        </div>
        <div className="form-group col-md-4">
          <label>{t("Executor")}</label>
          <input
            type="text"
            className="form-control"
            value={filterData.companyName}
            onChange={(e) =>
              setFilterData({ ...filterData, companyName: e.target.value })
            }
          />
        </div>
        <div className="form-group col-md-4">
          <label>{t("ContractNumber")}</label>
          <input
            type="text"
            className="form-control"
            value={filterData.contractNumber}
            onChange={(e) =>
              setFilterData({ ...filterData, contractNumber: e.target.value })
            }
          />
        </div>
        <div className="form-group col-md-4">
          <label>{t("Status")}</label>
          <Select
            placeholder={t("SelectFromList")}
            options={statusOptions}
            onChange={(e) => {
              setFilterData({ ...filterData, statuses: e });
            }}
            menuPortalTarget={document.body}
            styles={style}
            value={filterData.statuses}
            isMulti
          />
        </div>
        <div className="col-md-12 text-right">
          <button
            type="submit"
            className="btn btn-primary"
            onClick={(e) => {
              e.preventDefault();
              handleCallback();
            }}
          >
            {t("Search")}
          </button>
          <button
            type="button"
            className="btn btn-cancel"
            style={{ marginLeft: "10px" }}
            onClick={resetFilters}
          >
            {t("CancelSearch")}
          </button>
        </div>
      </div>
    </form>
  );
};

export default SearchInput;
