import Modal from "react-bootstrap/Modal";
import "./Modal.style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

const DialogModal = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      styles={{ zIndex: 9999 }}
      className={props.wide ? "wide-modal" : ""}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.title}
          {props.download && (
            <>
              &nbsp;
              <button
                type="button"
                className="btn btn-primary table-btn"
                onClick={() => {
                  props.downloadDocument();
                }}
              >
                <FontAwesomeIcon icon={solid("download")} />
              </button>
            </>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.children}</Modal.Body>
    </Modal>
  );
};

export default DialogModal;
