import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { format } from "date-fns";
import InputStatus from "../../common/input-status/InputStatus";
import HistoryStatus from "../../common/history-status/HistoryStatus";
import CollapsedData from "../../common/collapsed-data/collapsedData.component";

const InputHistory = ({ history }) => {
  return (
    <CollapsedData title={"Istorija procesa"}>
      {history.map((item, index) => {
        return (
          <span key={index}>
            <div className="row collapsed-item">
              <div className="arrow-container">
                <HistoryStatus value={item.actionType} />
                <FontAwesomeIcon
                  className="arrow-width"
                  icon={solid("right-long")}
                />
                <span>{item.actorName}</span>
                <span>
                  {format(new Date(item.actionDateTime), "dd.MM.yyyy. HH:mm")}
                </span>
              </div>
              <div className="rect-div">
                <InputStatus value={item.newStatus} />
              </div>
            </div>
            {item.comment && (
              <div className="row collapsed-item comment-item">
                <label>Komentar:</label>
                <br />
                <span>{item.comment}</span>
              </div>
            )}
          </span>
        );
      })}
    </CollapsedData>
  );
};

export default InputHistory;
