import {  toast } from "react-toastify";
import Cookies from "js-cookie";

const options = {
    hideProgressBar: true,
    position: "top-center",
    autoClose: 10000,
    zIndex: 100000,
}

const successOptions = {
    hideProgressBar: true,
    position: "top-center",
    autoClose: 1500,
    zIndex: 100000,
}

//var language = Cookies.get('i18next');
var language = "srb";

export const Success = async (message) => {
    let jsonFile = await loadJson(language);
    toast.success(jsonFile[message], successOptions)
}

export const Error = async (message) => {
    let jsonFile = await loadJson(language);
    toast.error(jsonFile[message], options);
}

export const Warning = (message) => {
    toast.warning(message, options);
}

export const Info = (message) => {
    toast.info(message, options);
}

const loadJson = (languageKey) => {
    return new Promise((res, rej) => {
      import(`../../../public/assets/locals/${languageKey}/translation.json`).then((data) => {
        res(data);
      });
    });
  };