import React, { useRef } from "react";
import Table from "../table/Table.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import {
  DeleteButton,
  DownloadButton,
  NewFileBtn,
} from "./multipleFiles.style";
import DocumentType from "../document-type/DocumentType";
import { format } from "date-fns";

const MultipleFiles = ({
  fileList,
  setFileList,
  accept,
  name,
  handleDownload,
  edit = true,
  getDocument,
}) => {
  const columns = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: "Naziv",
      accessor: "name",
      Cell: ({ row }) => {
        return edit ? (
          row.original.name
        ) : (
          <a
            href="#0"
            onClick={() => getDocument(row.original.id, row.original.name)}
          >
            {row.original.name}
          </a>
        );
      },
    },
    {
      Header: "Tip dokumenta",
      accessor: "documentType",
      Cell: ({ value }) => {
        return <DocumentType value={value} />;
      },
    },
    {
      Header: "Datum kreiranja",
      accessor: "createdDateTimeOnUtc",
      Cell: ({ row }) => {
        return (
          <div>
            {row.original.createdDateTimeOnUtc
              ? format(
                  new Date(row.original.createdDateTimeOnUtc),
                  "dd.MM.yyyy"
                )
              : ""}
          </div>
        );
      },
    },
    {
      accessor: "delete",
      className: "two-buttons-column",
      Cell: ({ row }) => {
        return (
          <>
            <DeleteButton
              type="button"
              title="Obriši fajl"
              className="pull-right"
              onClick={() => removeElement(row.id)}
            >
              <FontAwesomeIcon icon={solid("times")} />
            </DeleteButton>
            {fileList[row.id].guid ? (
              <DownloadButton
                title="Preuzmi fajl"
                type="button"
                className="pull-right"
                onClick={() => download(row.id)}
              >
                <FontAwesomeIcon icon={solid("download")} />
              </DownloadButton>
            ) : (
              <></>
            )}
          </>
        );
      },
    },
  ];

  const inputRef = useRef(null);

  const handleUploadClick = () => {
    inputRef.current.value = null;
    inputRef.current?.click();
  };

  const removeElement = (id) => {
    const arrayCopy = [...fileList];
    arrayCopy.splice(id, 1);
    setFileList(arrayCopy);
  };

  const download = (id) => {
    var fileForDownload = fileList.filter((el, index) => {
      if (index == id) return el;
    });

    fileForDownload && handleDownload(fileForDownload[0]);
  };

  return (
    <>
      <div className="form-group">
        <div>
          {fileList?.length > 0 ? (
            <Table
              columns={columns}
              data={fileList}
              hiddenColumn={["id", !edit && "delete", edit && "documentType", edit && "createdDateTimeOnUtc"]}
              serverSide={false}
              rememberFilter={false}
            />
          ) : (
            <></>
          )}
          {edit && (
            <>
              <NewFileBtn onClick={handleUploadClick} type="button">
                <FontAwesomeIcon icon={solid("plus")} />
                Dodaj
              </NewFileBtn>
              <input
                type="file"
                ref={inputRef}
                onChange={(event) => {
                  const files = event.target.files
                    ? [...fileList, ...event.target.files]
                    : [fileList];
                  setFileList(files);
                }}
                hidden
                accept={accept}
                name={name}
                multiple
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default MultipleFiles;
