import { Error, Success } from "../toastify/toastify";

const HandlingResponse = (response) => {
  var messageCode = "200";

  switch (response.ErrorCode) {
    case 200: { //Success
      Success(messageCode);
      return "Success";
    }
    case 400: { //Error
      messageCode = "400";
      Error(messageCode);
      return "Error";
    }
    //Common errors
    case 0: {
      if (response.ErrorCode)
        messageCode = response.ErrorCode;
      else messageCode = '400';

      Error(messageCode);
      return 'Error';
    }
    case 2: { //Validation
      return { code: response.ErrorCode, data: response.ErrorData };
    }
    case 3: {
      if (response.ErrorCode)
        messageCode = response.ErrorCode;
      else messageCode = '400';

      Error(messageCode);
      return 'Error';
    }
    //InternalUserServiceCodes
    case 1000: {
      if (response.ErrorCode)
        messageCode = response.ErrorCode;
      else messageCode = '400';

      Error(messageCode);
      return 'Error';
    }
    case 1001: {
      if (response.ErrorCode)
        messageCode = response.ErrorCode;
      else messageCode = '400';

      Error(messageCode);
      return 'Error';
    }
    //ExternalUserServiceCodes
    case 1500: {
      if (response.ErrorCode)
        messageCode = response.ErrorCode;
      else messageCode = '400';

      Error(messageCode);
      return 'Error';
    }
    case 1501: {
      if (response.ErrorCode)
        messageCode = response.ErrorCode;
      else messageCode = '400';

      Error(messageCode);
      return 'Error';
    }
    case 1502: {
      if (response.ErrorCode)
        messageCode = response.ErrorCode;
      else messageCode = '400';

      Error(messageCode);
      return 'Error';
    }
    case 1503: {
      if (response.ErrorCode)
        messageCode = response.ErrorCode;
      else messageCode = '400';

      Error(messageCode);
      return 'Error';
    }
    case 1504: {
      if (response.ErrorCode)
        messageCode = response.ErrorCode;
      else messageCode = '400';

      Error(messageCode);
      return 'Error';
    }
    case 1505: {
      if (response.ErrorCode)
        messageCode = response.ErrorCode;
      else messageCode = '400';

      Error(messageCode);
      return 'Error';
    }
    //InputWorkflowServiceCodes
    case 2000: {
      if (response.ErrorCode)
        messageCode = response.ErrorCode;
      else messageCode = '400';

      Error(messageCode);
      return 'Error';
    }
    case 2001: {
      if (response.ErrorCode)
        messageCode = response.ErrorCode;
      else messageCode = '400';

      Error(messageCode);
      return 'Error';
    }
    //Replacements
    case 2500: {
      if (response.ErrorCode)
        messageCode = response.ErrorCode;
      else messageCode = '400';

      Error(messageCode);
      return 'Error';
    }
    case 2501: {
      if (response.ErrorCode)
        messageCode = response.ErrorCode;
      else messageCode = '400';

      Error(messageCode);
      return 'Error';
    }
    case 2502: {
      if (response.ErrorCode)
        messageCode = response.ErrorCode;
      else messageCode = '400';

      Error(messageCode);
      return 'Error';
    }
    case 2503: {
      if (response.ErrorCode)
        messageCode = response.ErrorCode;
      else messageCode = '400';

      Error(messageCode);
      return 'Error';
    }
    default: {
      Error("0");
      break;
    }
  }
};

export default HandlingResponse;
