export const serializeObjectIntoFormData = (formData, obj) => {
    Object.keys(obj).forEach((key) => {
        if (obj[key] != null && obj[key] !== "") {
            if (Array.isArray(obj[key])) {
                let array = obj[key];
                serializeFormDataArray(key, array, formData);
            }
            else if (checkIfValueIsObjectAndNotNull(obj[key])) {
                if (obj[key] instanceof File) {
                    formData.append(key + ".file", obj[key]);
                    formData.append(key + ".name", obj[key].name);
                }
                else if (obj[key] instanceof Date) {
                    var datestr = (new Date(obj[key])).toUTCString();
                    formData.append(key, datestr);
                }
                else serializeNestedObjectIntoFormData(formData, obj[key], key)
            }
            else formData.append(key, obj[key]);

        }
    })
}
//akcija koja se poziva ukoliko se u modelu nalazi drugi model
const serializeNestedObjectIntoFormData = (formData, obj, parentKey) => {
    Object.keys(obj).forEach((key) => {
        //proveri ovo ako se desi situacija da se u modelu nalazi model u kom se nalazi novi model
        if (checkIfValueIsObjectAndNotNull(obj[key])) {
            if(Array.isArray(obj[key]))
                serializeFormDataArray(parentKey + "." + key, obj[key], formData);
            else {
                if (obj[key] instanceof Date) {
                    var datestr = (new Date(obj[key])).toUTCString();
                    formData.append(parentKey + "." + key, datestr);
                }
                else serializeNestedObjectIntoFormData(formData, obj[key], key)
            }
        }
        else if (obj[key] != null && obj[key] !== "") {
            if (Array.isArray(obj[key])) {
                let array = obj[key];
                serializeFormDataArray(key, array, formData);
            } else {
               formData.append(parentKey + "." + key, obj[key]);
            }
        }
    })
}

const serializeFormDataArray = (parentKey, arrayObject, formData) => {
    if (checkIfValueIsObjectAndNotNull(arrayObject) && arrayObject.length === undefined) {
        Object.keys(arrayObject).forEach((key) => {
            formData.append(parentKey + "." + key, arrayObject[key]);
        })
    }
    else {
        for (let i = 0; i < arrayObject.length; i++) {
            if (checkIfValueIsObjectAndNotNull(arrayObject[i])) {
                let keys = Object.keys(arrayObject[i]);
                for (let j = 0; j < keys.length; j++) {
                    let keyName = parentKey + "[" + i + "]." + keys[j];
                    let keyValue = arrayObject[i][keys[j]];
                    if (checkIfValueIsObjectAndNotNull(keyValue)) {
                        serializeFormDataArray(keyName, keyValue, formData);
                    } else {
                        if (keyValue !== null && keyValue !== undefined && keyValue !== '') {
                            formData.append(keyName, keyValue);
                        }
                    }
                }
            } else {
                let keyName = parentKey;
                let keyValue = arrayObject[i];
                if (keyValue != null || keyValue != undefined) {
                    formData.append(keyName, keyValue);
                }
            }
        }
    }
};

const checkIfValueIsObjectAndNotNull = (value) => {
    return typeof value == "object" && value != null;
};