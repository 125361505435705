import { createContext, useEffect,useLayoutEffect, useState } from "react";

const AppUrlContext = createContext(true);

export const AppUrlProvider = ({children}) => {
    const [appPath, setAppPath] = useState("/");
    const [appName, setAppName] = useState("");

    useLayoutEffect(() => {
        let appPathState = localStorage.getItem('appPath');
        setAppPath(appPathState);
        let appNameState = localStorage.getItem('appName');
        setAppName(appNameState);
      }, []);

      useEffect(() => {
        if(appPath) {
            localStorage.setItem('appPath', appPath);
            localStorage.setItem('appName', appName)
        }
      }, [appPath, appName]);

    return (
        <AppUrlContext.Provider value={{ appPath, setAppPath, appName, setAppName }}>
            {children}
        </AppUrlContext.Provider>
    )
}

export default AppUrlContext;