import CompanyStatement from "../../components/company-statement/companyStatement.component";
import InputPersons from "../../components/input-persons/inputPersons.component";
import InputEquipments from "../../components/input-equipments/inputEquipments.component";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import Client from "../../api-services/client";
import { InputModel } from "../../models/InputModels";
import InputDocuments from "../../components/input-documents/inputDocuments.component";
import { serializeObjectIntoFormData } from "../../utils/serialize-form-data/serializeFormData";
import {
  StatusForFirstStep,
  StatusForThirdStep,
} from "../../common/input-status/InputStatus";
import MultipleFiles from "../../common/multiple-files/multipleFiles.component";
import api from "../../api";
import { env } from "../../env";
import { MainContainer } from "../../common/layout/Layout.style";
import DialogModal from "../../common/modal/Modal.component";
import InputHistory from "../../components/input-history/inputHistory.component";
import Joi from "joi-browser";
import {
  handleSave,
  validateForm,
  serverValidation,
} from "../../common/validation/validation";
import ButtonWithConfirmation from "../../common/button-with-confirmation/ButtonWithConfirmation.component";
import InputStatus from "../../common/input-status/InputStatus";
import AlertDialog from "../../common/alert-dialog/AlertDialog.component";

var baseURL = env.REACT_APP_API_URL_EEP;

const CreateInputWithBZR = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const [model, setModel] = useState(new InputModel());
  const [history, setHistory] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [errors, setErrors] = useState({});
  const [personsError, setPersonsError] = useState(false);

  const [firstAttachment, setFirstAttachment] = useState();
  const firstAttachmentRef = useRef(null);
  const [secondAttachment, setSecondAttachment] = useState();
  const secondAttachmentRef = useRef(null);
  const [documents, setDocuments] = useState([]);
  const [doc, setDoc] = useState(null);
  const [docName, setDocName] = useState("");
  const [showDoc, setShowDoc] = useState(false);
  const [showComm, setShowComm] = useState(false);
  const [rejectComment, setRejectComment] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  const schema = {
    companyName: Joi.string().required(),
    companyAddressAndCity: Joi.string().required(),
    contractNumber: Joi.string().required(),
    subjectMatterOfTheContract: Joi.string().required(),
    responsiblePersonFirstName: Joi.string().required(),
    responsiblePersonLastName: Joi.string().required(),
    responsiblePersonEmail: Joi.string().email().required(),
    responsiblePersonPhoneNumber: Joi.string().required(),
    replacementResponsiblePersonFirstName: Joi.string().required(),
    replacementResponsiblePersonLastName: Joi.string().required(),
    replacementResponsiblePersonEmail: Joi.string().email().required(),
    replacementResponsiblePersonPhoneNumber: Joi.string().required(),
    bzrResponsiblePersonFirstName: Joi.string().required(),
    bzrResponsiblePersonLastName: Joi.string().required(),
    bzrResponsiblePersonPhoneNumber: Joi.string().required(),
    bzrResponsiblePersonEmail: Joi.string().email().required(),
  };

  var client = new Client();

  useEffect(() => {
    if (id) {
      getInput(id);
    }
  }, []);

  const ShowDocument = () => setShowDoc(true);
  const HideDocument = () => setShowDoc(false);
  const ShowComment = () => setShowComm(true);
  const HideComment = () => setShowComm(false);
  const ShowAlertDialog = () => setShowAlert(true);
  const HideAlertDialog = () => setShowAlert(false);

  const getInput = async (id) => {
    let data = await client.GetInput(id);
    setDocuments(data.inputWorkflowDocuments);
    data.inputWorkflowDocuments = [];
    setModel(data);
    setHistory(data.inputWorkflowHistories);
    let last =
      data.inputWorkflowHistories[data.inputWorkflowHistories.length - 1];
    if (last.comment) {
      setRejectComment(last.comment);
      ShowComment();
    }
    if (data.status != 6) {
      setDisabled(true);
    }
  };

  const handleChange = (prop, value) => {
    //setModel({ ...model, [prop]: value });
    const obj = { name: prop, value: value };
    const result = handleSave(obj, model, errors, schema, t);
    setModel(result.data);
    setErrors(result.errors);
  };

  const handleSAPChange = (contract) => {
    const obj = {
      ...model,
      fkSapcontract: contract?.id,
      contractNumber: contract ? contract.contractNumber : "",
      companyName: contract ? contract.contractingParty : "",
      companyAddressAndCity: contract ? contract.address : "",
    };
    setModel(obj);

    if (contract) {
      setErrors({
        ...errors,
        contractNumber: null,
        companyName: null,
        companyAddressAndCity: null,
      });
    }
  };

  const handleAddPerson = (data) => {
    setModel({ ...model, inputWorkflowPeople: data });
    if (data.length > 0) {
      setPersonsError(false);
    } else {
      setPersonsError(true);
    }
  };

  const handleAddEquipment = (data) => {
    setModel({ ...model, inputWorkflowEquipments: data });
  };

  const checkValidation = () => {
    const errorsResult = validateForm(model, schema, t);
    setErrors(errorsResult);

    let errorPersons = model.inputWorkflowPeople.length < 1;
    if (errorPersons) {
      setPersonsError(true);
    } else {
      setPersonsError(false);
    }
    if (!errorsResult && !errorPersons) {
      ShowAlertDialog();
    }
  };

  const createInput = async () => {
    model.inputWorkflowHistories = [];
    if (model.status === 6) {
      var response = await client.UpdateInput(model);
    } else {
      var response = await client.CreateInput(model);
    }
    handleResponse(response);
  };

  const finishThirdStep = async () => {
    model.inputWorkflowHistories = [];
    var formData = new FormData();
    serializeObjectIntoFormData(formData, model);

    //Appending first and second attachment
    if (firstAttachment && secondAttachment) {
      formData.append(
        "inputWorkflowDocuments[" + 0 + "].file",
        firstAttachment
      );
      formData.append(
        "inputWorkflowDocuments[" + 0 + "].name",
        firstAttachment.name
      );
      formData.append("inputWorkflowDocuments[" + 0 + "].documentType", 1);

      formData.append(
        "inputWorkflowDocuments[" + 1 + "].file",
        secondAttachment
      );
      formData.append(
        "inputWorkflowDocuments[" + 1 + "].name",
        secondAttachment.name
      );
      formData.append("inputWorkflowDocuments[" + 1 + "].documentType", 2);

      let response = await client.FinishThirdStep(formData);
      handleResponse(response);
    }
  };

  const handleResponse = (response) => {
    if (response.code === 2) {
      let result = serverValidation(response.data, t);
      setErrors(result);
    } else {
      navigate("/");
    }
  };

  const getDocument = async (id, name) => {
    let response = await api.get(
      baseURL + "/InputWorkflow/GetDocument?id=" + id,
      {
        responseType: "blob",
      }
    );
    var blob = new Blob([response.data], { type: response.data.type });
    var url = window.URL.createObjectURL(blob);
    setDoc(url);
    setDocName(name);
    ShowDocument();
  };

  const downloadDocument = () => {
    const link = document.createElement("a");
    link.href = doc;
    link.download = docName;
    link.click();
  };

  return (
    <>
      {history.length > 0 && <InputHistory history={history} />}
      {documents.length > 0 && (
        <MainContainer>
          <h5>Dokumentacija</h5>
          <MultipleFiles
            fileList={documents}
            setFileList={setDocuments}
            accept={".pdf"}
            name={"documents"}
            edit={false}
            getDocument={getDocument}
          />
        </MainContainer>
      )}
      <MainContainer>
        {id && (
          <>
            <h4>
              <span style={{ fontWeight: "400" }}>{t("RequestNumber")}: </span>
              {model.ledgerNumber}
            </h4>
            <h5>
              <span style={{ fontWeight: "400" }}>{t("Status")}: </span>
              <InputStatus value={model.status} />
            </h5>
            <br />
          </>
        )}
        <CompanyStatement
          data={model}
          handleChange={handleChange}
          handleSAPChange={handleSAPChange}
          errorData={errors}
          disabled={disabled}
          withBzr={true}
        />
        <br />
        <InputPersons
          handleAddPerson={handleAddPerson}
          persons={model.inputWorkflowPeople}
          withBzr={true}
          disabled={disabled}
        />
        {personsError && (
          <div style={{ marginTop: "10px" }} className="text-danger">
            {t("AtLeastOnePersonError")}
          </div>
        )}
        <br />
        <br />
        <InputEquipments
          handleAddEquipment={handleAddEquipment}
          equipments={model.inputWorkflowEquipments}
          disabled={disabled}
        />
        <br />
        <br />
        <div className="clearfix">
          {StatusForThirdStep.includes(model.status) && (
            <>
              <InputDocuments
                firstAttachment={firstAttachment}
                setFirstAttachment={setFirstAttachment}
                firstAttachmentRef={firstAttachmentRef}
                secondAttachment={secondAttachment}
                setSecondAttachment={setSecondAttachment}
                secondAttachmentRef={secondAttachmentRef}
                id={model.id}
                withBzr={true}
              />
              <ButtonWithConfirmation
                className="btn btn-accept pull-right"
                onClick={finishThirdStep}
                contentText={"Unos će biti poslat."}
                handleYesText={"Potvrdi"}
                disabled={!firstAttachment || !secondAttachment}
              >
                Pošalji unos
              </ButtonWithConfirmation>
            </>
          )}
          <br />
          {StatusForFirstStep.includes(model.status) && (
            <button
              type="button"
              className="btn btn-accept pull-right"
              onClick={checkValidation}
            >
              Pošalji unos
            </button>
          )}
        </div>
        <DialogModal
          show={showDoc}
          onHide={HideDocument}
          title={docName}
          download={true}
          downloadDocument={downloadDocument}
        >
          <div>
            <iframe src={doc} style={{ width: "100%", height: "800px" }} />
          </div>
        </DialogModal>
        <DialogModal
          show={showComm}
          onHide={HideComment}
          title={<InputStatus value={model.status} />}
        >
          <label>Komentar vraćanja/odbijanja: </label>
          <div>{rejectComment}</div>
        </DialogModal>
        {showAlert && (
          <AlertDialog
            handleYes={() => {
              HideAlertDialog();
              createInput();
            }}
            handleNo={() => {
              HideAlertDialog();
            }}
            show={showAlert}
            contentText={"Unos će biti kreiran."}
            handleYesText={"Potvrdi"}
            className={"btn btn-accept"}
          ></AlertDialog>
        )}
      </MainContainer>
    </>
  );
};

export default CreateInputWithBZR;
