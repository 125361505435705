import React, { useEffect } from "react";
import useAppUrl from "../../../hooks/useAppUrl";

const NotAllowed = () => {
    const { setAppPath, setAppName } = useAppUrl();
  useEffect(() => {
    setAppName("");
    setAppPath("/");
  }, [])
  
  return (
    <>
      <h2>
        Немате довољан ниво привилегија да бисте приступили овој страници!
      </h2>
    </>
  );
};

export default NotAllowed;
