import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

const CollapsedData = (props) => {
  const [show, setShow] = useState(false);

  return (
    <div className="collapsed-container">
      <button
        className="btn btn-primary col-md-12 collapsed-title"
        onClick={() => setShow(!show)}
      >
        {props.title}
        {show ? (
          <FontAwesomeIcon className="pull-right" icon={solid("arrow-up")} />
        ) : (
          <FontAwesomeIcon className="pull-right" icon={solid("arrow-down")} />
        )}
      </button>
      {show && <div className="collapsed-items">{props.children}</div>}
    </div>
  );
};

export default CollapsedData;
