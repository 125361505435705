export const style = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    control: (base, state) => ({
        ...base,
        borderColor: state.isFocused ? '#e81f27' : base.borderColor,
        boxShadow: state.isFocused && '0 0 0 0.25rem rgb(197 0 0 / 25%)',
        '&:hover': {
            borderColor: state.isFocused
                ? '#e81f27'
                : base.borderColor,
        }
    }),
    option: (provided, state) => ({
        ...provided,
        background: state.isSelected ? "red" : "white",
        ":hover": {
            background: "#ffeaeb",
            color: "#000",
            cursor: "pointer",
        },
    }),
}