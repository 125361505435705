import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

const languages = [
    {
      code: 'sr',
      name: 'Српски',
      country_code: 'rs',
    },
    {
      code: 'srb',
      name: 'Srpski',
      country_code: 'rs',
    },
    {
      code: 'en',
      name: 'English',
      country_code: 'gb',
    }
  ]

  i18next
    .use(HttpApi)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        supportedLngs: ['en', 'sr', 'srb'],
        fallbackLng: 'srb',
        debug: false,
        detection: {
            order: ['path', 'cookie', 'htmlTag'],
            caches: ['cookie'],
        },
        backend: {
            loadPath: '/assets/locals/{{lng}}/translation.json'
        }
    })