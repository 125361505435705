import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import Table from "../../common/table/Table.component";
import DialogModal from "../../common/modal/Modal.component";
import CreateInputPerson from "../create-input-person/createInputPerson.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { InputPersonModel } from "../../models/InputModels";
import Joi from "joi-browser";
import {
  handleSave,
  validateForm,
  serverValidation,
} from "../../common/validation/validation";

const InputPersons = ({ handleAddPerson, persons, withBzr, disabled }) => {
  const { t } = useTranslation();
  const tableName = "Input_Persons";
  const [edit, setEdit] = useState(false);
  const [personIndex, setPersonIndex] = useState(-1);
  const [personData, setPersonData] = useState(new InputPersonModel());
  const [errors, setErrors] = useState({});
  const [personIdError, setPersonIdError] = useState(false);

  const columns = [
    {
      Header: t("Name"),
      accessor: "firstName",
    },
    {
      Header: t("LastName"),
      accessor: "lastName",
    },
    {
      Header: t("Workplace"),
      accessor: "employeePosition",
    },
    {
      Header: t("IdentityCardNumber"),
      accessor: "identityCardNumber",
    },
    {
      Header: t("IdentityCardPlace"),
      accessor: "identityCardPlace",
    },
    {
      Header: t("PassportNumber"),
      accessor: "identityPassportNumber",
    },
    {
      Header: t("PassportPlace"),
      accessor: "identityPassportPlace",
    },
    {
      Header: t("Foreigner"),
      accessor: "foreigner",
      Cell: ({ row }) => {
        return <>{row.original.foreigner ? "Da" : "Ne"}</>;
      },
    },
    {
      accessor: "edit",
      className: "two-buttons-column",
      Cell: ({ row }) => {
        return (
          <>
            <button
              type="button"
              className="btn btn-incomplete table-btn"
              title="Izmena zaposlenog"
              onClick={() => {
                setEdit(true);
                setPersonIndex(row.index);
                setPersonData(row.original);
                setErrors({});
                ShowDialog();
              }}
            >
              <FontAwesomeIcon icon={solid("pencil")} />
            </button>
            <button
              type="button"
              className="btn btn-danger table-btn"
              title="Brisanje zaposlenog"
              onClick={() => removePerson(row.index)}
            >
              <FontAwesomeIcon icon={solid("trash")} />
            </button>
          </>
        );
      },
    },
  ];

  const [data, setData] = useState([]);

  const [show, setShow] = useState(false);

  const schema = {
    firstName: Joi.string().required(),
    lastName: Joi.string().required(),
    employeePosition: withBzr ? Joi.string().required() : Joi.optional(),
    identityCardNumber: Joi.string().length(9).allow(""),
    identityCardPlace: Joi.optional(),
    identityPassportNumber: Joi.optional(),
    identityPassportPlace: Joi.optional(),
  };

  useEffect(() => {
    if (persons) {
      setData(persons);
    }
  }, [persons]);

  const ShowDialog = () => setShow(true);
  const HideDialog = () => setShow(false);

  const handleChange = (prop, value, person) => {
    const obj = { name: prop, value: value };
    const result = handleSave(obj, person, errors, schema, t);
    setErrors(result.errors);
  };

  const addPerson = (person) => {
    if (person.foreigner) {
      schema.identityPassportNumber = Joi.string().required();
      schema.identityPassportPlace = Joi.string().required();
      schema.identityCardNumber = Joi.optional();
      schema.identityCardPlace = Joi.optional();
    } else {
      schema.identityCardNumber = Joi.string().length(9).required();
      schema.identityCardPlace = Joi.string().required();
      schema.identityPassportNumber = Joi.optional();
      schema.identityPassportPlace = Joi.optional();
    }

    const errorsResult = validateForm(person, schema, t);
    setErrors(errorsResult);

    let index = edit ? personIndex : -1;
    let personIdExist = person.foreigner
      ? false
      : data.filter(
          (x, i) =>
            i != index &&
            x.identityCardNumber == person.identityCardNumber &&
            person.identityCardNumber != ""
        ).length > 0;

    setPersonIdError(personIdExist);

    if (!errorsResult && !personIdExist) {
      if (edit) {
        let dataCopy = data;
        dataCopy[personIndex] = person;
        setData(dataCopy);
        handleAddPerson(dataCopy);
      } else {
        setData([...data, person]);
        handleAddPerson([...data, person]);
      }
      HideDialog();
    }
  };

  const removePerson = (index) => {
    let newData = data.filter((v, i) => i !== index);
    setData(newData);
    handleAddPerson(newData);
  };

  return (
    <>
      {data.length > 0 && (
        <>
          <h5>{t("InputPersons")}</h5>
          <Table
            columns={columns}
            data={data}
            tableName={tableName}
            rememberFilter={false}
            hiddenColumn={disabled && "edit"}
          />
        </>
      )}
      {!disabled && (
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            setEdit(false);
            setPersonIndex(-1);
            setPersonData(new InputPersonModel());
            setErrors({});
            ShowDialog();
          }}
        >
          {t("AddInputPerson")}
        </button>
      )}
      <DialogModal
        show={show}
        onHide={HideDialog}
        title={edit ? "Izmena zaposlenog" : t("InputPerson")}
      >
        <CreateInputPerson
          propertyChange={handleChange}
          addPerson={addPerson}
          personData={personData}
          edit={edit}
          errors={errors}
          personIdError={personIdError}
          setPersonIdError={setPersonIdError}
          withBzr={withBzr}
        ></CreateInputPerson>
      </DialogModal>
    </>
  );
};

export default InputPersons;
