import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { InputEquipmentModel } from "../../models/InputModels";
import AsyncSelect from "react-select/async";
import Client from "../../api-services/client";
import { style } from "../../common/select-style/SelectStyle";

const CreateInputEquipment = ({
  propertyChange,
  addEquipment,
  equipmentData,
  edit,
  errors
}) => {
  const { t } = useTranslation();
  const [equipment, setEquipment] = useState(new InputEquipmentModel());
  const [searchEquipment, setSearchEquipment] = useState(new InputEquipmentModel());

  var client = new Client();

  useEffect(() => {
    setEquipment(equipmentData);
  }, [equipmentData]);

  const handleChange = (value) => {
    if (value) {
      setEquipment(value);
      setSearchEquipment(value);
    } else {
      setEquipment(new InputEquipmentModel());
      setSearchEquipment(new InputEquipmentModel());
    }
  };

  const getCreatedEquipments = async (value) => {
    if (value.length > 2) {
      let data = {
        filter: {
          nameOrModelOrIdentificationNumber: value,
        },
        page: 1,
        pageSize: 10
      };
      let response = await client.SearchEquipments(data, true);
      return response.results;
    }
  };

  const onPropertyChange = (prop, value) => {
    let data = { ...equipment, [prop]: value };
    setEquipment(data);
    propertyChange(prop, value, data);
  }

  return (
    <>
      <div className="form-group">
        <label>Dodavanje već kreirane opreme</label>
        <AsyncSelect
          cacheOptions
          defaultOptions
          styles={style}
          value={searchEquipment}
          getOptionLabel={(e) =>
            e.id &&
            "(" +
            e.identificationNumber +
            ") " +
            e.name + " - " +
            e.model
          }
          getOptionValue={(e) => e.id}
          loadOptions={getCreatedEquipments}
          onChange={handleChange}
          placeholder="Potrebno je uneti minimum 3 karaktera..."
          isClearable
        />
      </div>
      <div className="form-group">
        <label>{t("EquipmentName")}</label>
        <input
          type="text"
          className="form-control"
          value={equipment.name}
          onChange={(e) => onPropertyChange("name", e.target.value)}
        />
        {errors?.name && (
          <div className="text-danger">{errors.name}</div>
        )}
      </div>
      <div className="form-group">
        <label>{t("Model")}</label>
        <input
          type="text"
          className="form-control"
          value={equipment.model}
          onChange={(e) =>
            onPropertyChange("model", e.target.value)
          }
        />
        {errors?.model && (
          <div className="text-danger">{errors.model}</div>
        )}
      </div>
      <div className="form-group">
        <label>{t("Type")}</label>
        <input
          type="text"
          className="form-control"
          value={equipment.type}
          onChange={(e) => onPropertyChange("type", e.target.value)}
        />
        {errors?.type && (
          <div className="text-danger">{errors.type}</div>
        )}
      </div>
      <div className="form-group">
        <label>{t("IdentificationNumber")}</label>
        <input
          type="text"
          className="form-control"
          value={equipment.identificationNumber}
          onChange={(e) =>
            onPropertyChange("identificationNumber", e.target.value)
          }
        />
        {errors?.identificationNumber && (
          <div className="text-danger">{errors.identificationNumber}</div>
        )}
      </div>
      <button
        type="button"
        className="btn btn-accept"
        onClick={() => {
          addEquipment(equipment);
        }}
      >
        {edit ? "Izmeni" : t("Add")}
      </button>
    </>
  );
};

export default CreateInputEquipment;
